<template>
  <div class="content">
    <div class="title-actions">
      <h5 class="title">{{ tournament.name }} - {{ division.name }} - {{ $t('deliverCup') }}</h5>
      <div class="btns">
        <b-form-input v-model="filter" :placeholder="$t('search')" autofocus />
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <b-table striped bordered :items="seasons" :fields="columns" :filter="filter">
      <template slot="actions" slot-scope="row">
        <div class="w-100 text-center">
          <b-button class="mx-2" variant="success" @click="openModal(row.item)">{{ $t('deliverCup') }}</b-button>
        </div>
      </template>
    </b-table>
    <b-form @submit.prevent="save">
      <b-modal id="modalDeliverCup" :title="title">
        <div class="form-group">
          <label for="team">{{ $t('team') }}</label>
          <select name="team" id="team" v-validate="'required'" v-model="cup.team_id" :state="validateState('team')" class="form-control" @change="findDt">
            <option value="">{{ $t('selectOption') }}</option>
            <option v-for="team in teams" :key="`sel-${team.id}`" :value="team.id">{{ team.name }}</option>
          </select>
          <span class="error-inputs">{{ errors.first('team') }}</span>
        </div>
        <div class="form-group">
          <label for="date">{{ $t('date') }}</label>
          <b-form-input type="date" id="date" name="date" v-model="cup.date" v-validate="'required'" :state="validateState('date')" />
          <span class="error-inputs">{{ errors.first('date') }}</span>
        </div>
        <div class="form-group">
          <label for="dt">DT</label>
          <select name="dt" id="dt" v-model="cup.dt" v-validate="'required'" :state="validateState('dt')" class="form-control">
            <option value="">{{ $t('selectOption') }}</option>
            <option v-for="player in players" :key="`pla-${player.id}`" :value="player.id">{{ player.nick }}</option>
          </select>
          <span class="error-inputs">{{ errors.first('dt') }}</span>
        </div>
        <template slot="modal-footer">
          <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalDeliverCup')">{{ $t('cancel') }}</b-button>
          <b-button type="submit" variant="primary">{{ $t('deliverCup') }}</b-button>
          <b-button v-if="type === 'SUPER'" type="button" variant="danger" @click="removeChampion">{{ $t('removeCup') }}</b-button>
        </template>
      </b-modal>
    </b-form>
    <!-- <b-modal id="modalDestroy" :title="$t('confirmTitleDelete')" headerBgVariant="danger" headerTextVariant="light">
      <p>{{$t('confirmTextDelete')}}</p>
      <strong>{{ season.name }}</strong>
      <div slot="modal-footer">
        <b-button @click="$root.$emit('bv::hide::modal', 'modalDestroy')">{{$t('cancel')}}</b-button>
        <b-button variant="danger" @click="destroy" class="ml-3">{{$t('delete')}}</b-button>
      </div>
    </b-modal> -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      filter: '',
      seasons: [],
      season: {},
      columns: [
        { key: 'name', label: this.$t('name'), sortable: true },
        { key: 'actions', label: '' }
      ],
      teams: [],
      type: null,
      cup: {
        team_id: '',
        dt: ''
      },
      title: '',
      players: [],
      tournament: {}
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'division'
    ])
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    destroy () {
      const path = `auth/${this.lang}/${this.console}/tournament/${this.tournament.id}/division/${this.division.id}/seasons/${this.season.id}/delete`
      this.$axios.delete(path).then(() => {
        this.seasons = this.seasons.filter(season => {
          return season.id !== this.season.id
        })
        this.$root.$emit('bv::hide::modal', 'modalDestroy')
      })
    },
    openModal (season) {
      this.title = `${this.$t('deliverCup')} - ${season.name}`
      this.season = JSON.parse(JSON.stringify(season))
      this.$root.$emit('bv::show::modal', 'modalDeliverCup')
    },
    removeChampion () {
      if (this.season.id) {
        const payload = {
          season_id: this.season.id
        }
        const path = `auth/${this.lang}/${this.console}/tournament/${this.tournament.id}/division/${this.division.id}/deliver-cup/remove-champion`
        this.$axios.post(path, payload).then(() => {
          this.cup = {
            team_id: '',
            dt: '',
            date: ''
          }
          this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
        })
      }
    },
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          this.cup.season_id = this.season.id
          const payload = this.cup
          const path = `auth/${this.lang}/${this.console}/tournament/${this.tournament.id}/division/${this.division.id}/deliver-cup/save`
          this.$axios.post(path, payload).then(() => {
            this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
            this.$root.$emit('bv::hide::modal', 'modalDeliverCup')
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    },
    fetchData () {
      const tournamentId = this.$route.params.tournamentId
      const path = `auth/${this.lang}/${this.console}/tournament/${tournamentId}/division/${this.division.id}/deliver-cup`
      this.$axios.get(path).then((response) => {
        const data = response.data
        this.seasons = data.seasons
        this.teams = data.teams
        this.type = data.type
        this.tournament = data.tournament
        document.title = `${this.tournament.name} - ${this.division.name} - ${this.$t('deliverCup')}`
      })
    },
    findDt () {
      const path = `auth/${this.lang}/${this.console}/tournament/${this.tournament.id}/division/${this.division.id}/deliver-cup/players/${this.cup.team_id}`
      this.$axios.get(path).then((response) => {
        this.players = response.data.data
      })
    }
  }
}
</script>
